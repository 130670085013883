import React, { useContext } from 'react';

import { Admin } from 'react-admin';

import CircularProgress from '@material-ui/core/CircularProgress';

import GenericResource from './GenericResource'
import { SettingsContext } from '../../Context';
import { getResourceName } from '../../utils';

const GenericAdmin = (props) => {
  const { children=[] } = props;
  const [settings] = useContext(SettingsContext);
 
  // Loading screen while settings are generated
  if(!settings) {
    return (      
      <div style={{ paddingTop: '50vh', width: '100%', height: '100vh', textAlign: 'center', backgroundColor: 'white'}}>
        <CircularProgress size={80}/>
      </div>
    )
  }
  
  // Get list of already defined entities in children
  let existingEntities = [];
  if(Array.isArray(children)) {
    children.filter(c => !!c?.props?.type).forEach(c => existingEntities.push(c.props.type))
  }
  else if(children?.props?.type) {
      existingEntities.push(children.props.type)
  }

  // Compute entities to generate settings.Types (enums are filtered)
  const {excludedTypes = []} = settings.Global
  const missingEntities = Object.entries(settings.Types || {})
                      .filter(e => e[1].enum === undefined)                 //filter enums
                      .filter(e => !existingEntities.includes(e[0]))        //filter already declared in children
                      .filter(e => !excludedTypes.includes(e[0]))           //filter excluded type in Admin props

  return (
    <Admin {...props}>
      {children}
      { missingEntities.map(([type, specs], index) => {
          return(
            <GenericResource key={type} type={type} name={getResourceName(type)} />
          )
        })
      }
    </Admin>
  );
}

export default GenericAdmin;