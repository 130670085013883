const en = {
  resources: {
    users: {
      fields: {
        personalProfile: {
          name: 'Personal Profile'
        }
      }
    },
    profiles: {
      fields: {
        services: 'Services',
        userProfiles: 'User Profiles',
        tags: 'Tags',
        followerLinks: 'Follower Links',
        followingLinks: 'Following Links',
      }
    },
    services: {
      fields: {
        ratings: 'Ratings',
        tags: 'Tags',
      }
    },
    userprofiles: {
      name: 'User Profile |||| User Profiles',
    },
    posts: {
      fields: {
        children: 'Children',
        ratings: 'Ratings',
        tags: 'Tags',
      }
    },
    posthashtags: {
      name: 'Post Hashtag |||| Post Hashtags',
    },
    profilelinks: {
      name: 'Profile Link |||| Profile Links',
    },
    profiletags: {
      name: 'Profile Tag |||| Profile Tags',
    },
    taglinks: {
      name: 'Tag Link |||| Tag Links'
    }

  }
}

export default en;