/**
 * 
 * JS version of Forecasting.AI.Web authService.tsx
 * 
 */
import { Log, /*User,*/ UserManager } from 'oidc-client';
// import Env from '../../Helper/Env'
import Env from '../settings';

export const hasSignedIn = () => {
  // TODO: check if user is connected by having an hint from a cookie
  // Maybe juste having current cookie is enough
  if (window && typeof window !== undefined) {
      return sessionStorage.getItem('oidc.user:' + Env.authority + ':' + Env.clientId) !== null
  }
  return false
}

export default class AuthService {
  // public userManager: UserManager;

  constructor() {
    const settings = {
      authority: Env.authority,
      client_id: Env.clientId,
      redirect_uri: `${Env.clientHost}signin-callback.html`,
      silent_redirect_uri: `${Env.clientHost}silent-renew.html`,
      post_logout_redirect_uri: `${Env.clientHost}`,
      response_type: 'id_token token',
      scope: Env.clientScope,
      automaticSilentRenew: true,
      monitorSession:false
    };

    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    return this.userManager.signoutRedirect();
  }
}


