/**
 * 
 * JS version of Forecasting.AI.Web apiService.tsx
 * 
 */

// import Env from '../../Helper/Env'
import Env from '../settings';
import AuthService from '../auth/AuthService';

export default class ApiService {
  // private authService: AuthService;
  // private headers: any;

  constructor() {
    this.authService = new AuthService();
    let localHeaders = {
      Accept: "application/json",
      profile: ""
    }

    if (sessionStorage.getItem('selectedProfile') !== null) {
      let value = sessionStorage.getItem('selectedProfile')
      if (value !== null) {
        this.headers = {
          ...localHeaders,
          profile: JSON.parse(value).id
        }
      }
    }
  }

  async getApi(resource) {
    let user = await this.authService.getUser()
    if (user && user.access_token) {
      let tokenResponse = await this._getApi(user.access_token, resource)
      if (tokenResponse.status === 401) {
        let renewedUser = await this.authService.renewToken()
        let renewedResponse = await this._getApi(renewedUser.access_token, resource)
        return renewedResponse
      }
      else {
        return tokenResponse
      }
    }
    else if (user) {
      let renewedUser = await this.authService.renewToken()
      let renewedResponse = await this._getApi(renewedUser.access_token, resource)
      return renewedResponse
    }

    return new Response()
  }

  async postApi(resource, stringifiedBody) {
    let user = await this.authService.getUser()
    if (user && user.access_token) {
      let tokenResponse = await this._postApi(user.access_token, resource, stringifiedBody)
      if (tokenResponse.status === 401) {
        let renewedUser = await this.authService.renewToken()
        let renewedResponse = await this._postApi(renewedUser.access_token, resource, stringifiedBody)
        return renewedResponse
      }
      else {
        return tokenResponse
      }
    }
    else if (user) {
      let renewedUser = await this.authService.renewToken()
      let renewedResponse = await this._postApi(renewedUser.access_token, resource, stringifiedBody)
      return renewedResponse
    }

    return new Response()
  }

  async putApi(resource, stringifiedBody) {
    let user = await this.authService.getUser()
    if (user && user.access_token) {
      let tokenResponse = await this._putApi(user.access_token, resource, stringifiedBody)
      if (tokenResponse.status === 401) {
        let renewedUser = await this.authService.renewToken()
        let renewedResponse = await this._putApi(renewedUser.access_token, resource, stringifiedBody)
        return renewedResponse
      }
      else {
        return tokenResponse
      }
    }
    else if (user) {
      let renewedUser = await this.authService.renewToken()
      let renewedResponse = await this._putApi(renewedUser.access_token, resource, stringifiedBody)
      return renewedResponse
    }

    return new Response()
  }

  async patchApi(resource, stringifiedBody) {
    let user = await this.authService.getUser()
    if (user && user.access_token) {
      let tokenResponse = await this._patchApi(user.access_token, resource, stringifiedBody)
      if (tokenResponse.status === 401) {
        let renewedUser = await this.authService.renewToken()
        let renewedResponse = await this._patchApi(renewedUser.access_token, resource, stringifiedBody)
        return renewedResponse
      }
      else {
        return tokenResponse
      }
    }
    else if (user) {
      let renewedUser = await this.authService.renewToken()
      let renewedResponse = await this._patchApi(renewedUser.access_token, resource, stringifiedBody)
      return renewedResponse
    }

    return new Response()
  }

  async deleteApi(resource) {
    let user = await this.authService.getUser()
    if (user && user.access_token) {
      let tokenResponse = await this._deleteApi(user.access_token, resource)
      if (tokenResponse.status === 401) {
        let renewedUser = await this.authService.renewToken()
        let renewedResponse = await this._deleteApi(renewedUser.access_token, resource)
        return renewedResponse
      }
      else {
        return tokenResponse
      }
    }
    else if (user) {
      let renewedUser = await this.authService.renewToken()
      let renewedResponse = await this._deleteApi(renewedUser.access_token, resource)
      return renewedResponse
    }

    return new Response()
  }

  async _getApi(token, resource) {
    let requestInit = {
      method: "GET",
      headers: {
        ...this.headers,
        Authorization: 'Bearer ' + token
      }
    }

    return await fetch(`${Env.faiApiEndpoint}${resource}`, requestInit)
  }

  async _postApi(token, resource, stringifiedBody) {
    let requestInit = {
      method: "POST",
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token,
      },
      body: stringifiedBody
    }

    return await fetch(`${Env.faiApiEndpoint}${resource}`, requestInit)
  }

  async _putApi(token, resource, stringifiedBody) {
    let requestInit = {
      method: "PUT",
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      },
      body: stringifiedBody
    }

    return await fetch(`${Env.faiApiEndpoint}${resource}`, requestInit)
  }

  async _patchApi(token, resource, stringifiedBody) {
    let requestInit = {
      method: "PATCH",
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      },
      body: stringifiedBody
    }

    return await fetch(`${Env.faiApiEndpoint}${resource}`, requestInit)
  }

  async _deleteApi(token, resource) {
    let requestInit = {
      method: "DELETE",
      headers: {
        ...this.headers,
        Authorization: 'Bearer ' + token
      }
    }

    return await fetch(`${Env.faiApiEndpoint}${resource}`, requestInit)
  }
}
