import React, { useContext } from 'react';

/* React admin */
import { Resource } from 'react-admin';

/* Material-ui */
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

/*  forecasting.ai */
import { SettingsContext } from '../../Context';
import { GenericList, GenericEdit, GenericShow, GenericCreate } from './generics';


/*  GenericResource */
const GenericResource = ({type, list=GenericList, edit=GenericEdit, show=GenericShow, create=GenericCreate, ...props}) => {

  const [allSettings] = useContext(SettingsContext)  
  const settings = allSettings.Types[type];
  return (
    <Resource {...props} 
      list={list} 
      edit={edit} 
      show={show} 
      create={!!settings?.create?.disabled ? undefined : create} 
      icon={ResourceIcon}
      options={{type: type}}/>
  )
};

export default GenericResource;


/*  ResourceIcon */
const useIconStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const ResourceIcon = (props) => { 
  const classes = useIconStyles();
  return (
    <div className={classes.root}>
      <Avatar className={classes.small}>{props.titleAccess[0]}</Avatar>
    </div>
  ); 
}
