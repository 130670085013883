
import React from 'react';

import { 
  ReferenceField,
  TextField,
} from 'react-admin';

import { recordFieldToId } from '../../utils'; 

export const GenericReferenceField = ({source, reference, textSource, ...props}) => {
  const record = recordFieldToId(props.record, source)
  return (
    <ReferenceField {...props} record={record} reference={reference} source={source}>
      <TextField source={textSource} />
    </ReferenceField>
  )
}
GenericReferenceField.defaultProps = { addLabel: true };

export default GenericReferenceField;