export const enums = {
    PostType: ['News', 'Review', 'Tuto', 'Feedback', 'Reply',' Private'],
    TagPriority: [ 'Low', 'Medium', 'High'],
    TagType: ["Generic", "Profile", "Country", "Service", "Category", "Business", "Computing", "Post"],
    Role: ['Member', 'Admin']
}

export function getChoices(enumType, enumValues) {
    if(!!enums[enumType] && enumValues?.length === enums[enumType].length) {
        return enums[enumType].map((e, i) => ({id: i, name: e}))
    }
    console.warn('No enum settings for ' + enumType)
    return enumValues.map((id) => ({id: id, name: id.toString()}));
}

export default getChoices;