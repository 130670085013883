import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    // type: 'light', // Switching the dark mode on is a single property value change.
    primary: {
        main: '#03a9f4',
    },
  },
  overrides: {
    MuiAppBar: { // override the styles of all instances of this component
      colorSecondary: {
        backgroundColor: "#03a9f4"
      },
    },
  },
});

export default theme