
import React from 'react';

import { 
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import { recordFieldToId } from '../../utils'; 

export const GenericReferenceArrayField = ({source, reference, resource, textSource, ...props}) => {
  const record = recordFieldToId(props.record, source)
  return (
    <ReferenceArrayField {...props} record={record} resource={resource} reference={reference} source={source} >
      <SingleFieldList>
        <ChipField source={textSource} />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}
GenericReferenceArrayField.defaultProps = { addLabel: true };

export default GenericReferenceArrayField;