import React from 'react';

import { 
    TextField
} from 'react-admin';


const GenericTextListField = (props) => {
    let { record, source, maxLength=50 } = props;
    
    if(record && record[source] && record[source].length > maxLength) {
        record[source] = record[source].slice(0, maxLength) + '...';
        return(
            <TextField {...props} record={record}/>
        )
    }
    return <TextField {...props} record={record}/>
}
GenericTextListField.defaultProps = { addLabel: true }

export default GenericTextListField;