
import React from 'react';

import { 
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';

import { recordFieldToId } from '../../utils';

export const GenericReferenceInput = ({source, reference, optionText, ...props}) => {
  const record = recordFieldToId(props.record, source)
  // const InputComponent = (props) => ['profiles', 'services', 'countries', 'users'].includes(reference) ? <AutocompleteInput {...props} /> : <SelectInput {...props} />;
 
  if(optionText === 'id') {
    return (
      <ReferenceInput {...props} 
        record={record} 
        source={source} 
        reference={reference}
        filterToQuery={val => { 
          // note: for post ratings: record[post]=id et pas {id: x}
          // Si besoin vérifier si objet avec prop id ou pas pour adapter
          let filter = !!val ? val : record && record[source] ? record[source] : "";
          return ({q: `Id.ToString().Contains("${filter}")`})
        }}
      >
        <AutocompleteInput 
          optionText={(record) => record.id.toString(10)} 
          matchSuggestion={(filter, choice) => {
            // console.log('matchSuggestion filter, choice.id:', filter, choice.id)
            return choice.id.toString(10).includes(filter) ? true : false;
          }}
        />
      </ReferenceInput>
    )
  }
  return (
    <ReferenceInput {...props} 
      record={record} 
      source={source} 
      reference={reference} 
    >
      <AutocompleteInput optionText={optionText} />
    </ReferenceInput>
  )
}

export default GenericReferenceInput;