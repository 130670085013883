
import React from 'react';

import { 
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

export const GenericArrayField = ({source, textSource, ...props}) => {
  return (
    <ArrayField {...props} source={source}>
      <SingleFieldList>
        <ChipField source={textSource} />
      </SingleFieldList>
    </ArrayField>
  )
}
GenericArrayField.defaultProps = { addLabel: true };

export default GenericArrayField;