import React from 'react';

import { 
    BooleanField
} from 'react-admin';


const GenericBooleanField = (props) => {
    const { record, source } = props;
    const value = record && record[source];
    return <BooleanField {...props} record={record} style={{ color: !!value ? 'green' : 'red'}} />
}
GenericBooleanField.defaultProps = { addLabel: true }

export default GenericBooleanField;