
/**
 * TODO and enable reslveBrowserocale in i18nProvider
 */
const fr = {
  resources: {
    users: {
        name: 'Utilisateur |||| Utilisateurs',
        fields: {
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            name: 'Nom',
            personnalProfile: {
              name: 'Profil Personnel'
            }
        }
    },
    profiles: {
      fields: {
        services: 'Services',
        userProfiles: 'Profils Utilisateurs',
        tags: 'Tags',
        followerLinks: 'Liens Suiveurs',
        followingLinks: 'Liens Suivis',
      }
    },
    services: {
      fields: {
        ratings: 'Notes',
        tags: 'Tags',
      }
    },
    userprofiles: {
      name: 'Profil Utilsateur |||| Profils Utilsateurs',
    },
    posts: {
      fields: {
        children: 'Enfants',
        ratings: 'Notes',
        tags: 'Tags',
      }
    },
    posthashtags: {
      name: 'Post Hashtag |||| Post Hashtags',
    },
    profilelinks: {
      name: 'Profile Link |||| Profile Links',
    },
    profiletags: {
      name: 'Profile Tag |||| Profile Tags',
    },
    taglinks: {
      name: 'Tag Link |||| Tag Links'
    }

  }
}

export default fr;