import { stringify } from 'query-string';

import ApiService from './ApiService';
import { getTypeName } from '../utils';
/**
  const dataProvider = {
    getList:    (resource, params) => Promise,
    getOne:     (resource, params) => Promise,
    getMany:    (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    create:     (resource, params) => Promise,
    update:     (resource, params) => Promise,
    updateMany: (resource, params) => Promise,
    delete:     (resource, params) => Promise,
    deleteMany: (resource, params) => Promise,
  }
 */

const uploadFileAsBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
    reader.onload = async () => {
      const b64Image = reader.result;
      let apiService = new ApiService();
      const data = { fileContent: b64Image, name: file.rawFile.name}
      const imageUrl = await apiService.postApi('images', JSON.stringify(data))
          .then(response => response.json())
          .then(json => convertApiResponse(json, 'SINGLE', 'images'));
      return resolve(imageUrl.data)
    }
  });

const convertApiResponse = (json, type, resource, params) => {
  switch(type) {
    case 'MULTI':
      let res = { data: json.multipleResult, total: json.totalCount };
      return res
    case 'SINGLE':
      return { data: json.singleResult };
    /*
    case 'CREATE':
      return { data: { ...params.data, id: json.id } };
    case 'DELETE':
      return { data: params.previousData };
    */
    default:
      return { data: json };
  }
}

const convertApiRequestData = (resource, params, settings) => {

  // Find images for conversion to base 64
  const imageFields = Object.keys(params.data).filter(k => k.slice(-5) === 'Image' && !!params.data[k] && params.data[k].rawFile instanceof File);
  const images = imageFields.map(field => params.data[field]);

  return Promise.all(images.map(uploadFileAsBase64))
  .then(b64images => {

    let { data } = params;
    imageFields.forEach((field, idx) => data[field] = b64images[idx]);

    const type = getTypeName(resource, settings);
    // Update references to {id: x} or [{id: x}, {id: y}..]
    const referenceArrayFields = Object.keys(params.data).filter(k => {
      let properties = settings?.Types[type]?.properties[k];
      return properties?.items?.$ref && properties?.type === "array" ? true : false
    })
    // console.log('referenceArrayFields', referenceArrayFields)
    referenceArrayFields.forEach(f => {
      if(data[f] && data[f].length > 0) {
        data[f] = data[f].map(o => ({id: o.id}))
      }
    })

    const referenceFields = Object.keys(params.data).filter(k => {
      let properties = settings?.Types[type]?.properties[k];
      if(!!properties?.$ref) {
        // do not convert enums
        const refType = properties.$ref.split('/').slice(-1)[0];
        if(!!settings?.Types[refType].enum) return false;
        return true;
      }
      return false
    })
    // console.log('referenceFields', referenceFields)
    referenceFields.forEach(f => {
      if(Number.isInteger(data[f])) {
        data[f] = {id: data[f]}
      }
    })

    return data;
  })

}


// export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
const dataProvider =  (settings) => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        let query = {
            p: !!page ? page : 1,
            size: !!perPage ? perPage : 10,
            // filter: JSON.stringify(params.filter),
        };
        // search
        if(!!params?.filter?.q) {
            query.q = params.filter.q;
        }
        // sort
        const { field, order } = params.sort;
        if(!!field) {
            query.order = field;
            query.asc = order === 'ASC';
        }
        const resource_path = `${resource}?${stringify(query)}`;
        let apiService = new ApiService();
        return apiService.getApi(resource_path)
          .then(response => response.json())
          .then(json => convertApiResponse(json, 'MULTI', resource, params));
    },

    getOne: (resource, params) => {
      const resource_path = `${resource}/${params.id}`;
      let apiService = new ApiService();
      return apiService.getApi(resource_path)
        .then(response => response.json())
        .then(json => convertApiResponse(json, 'SINGLE', resource, params));
    },


    getMany: (resource, params) => {
        // if we receive a list of objects, we need to convert to a list of ids
        const ids = params.ids.map(p => (typeof p === 'object' ? p.id : p));
        // we format the query with id=1 or id=2...
        const query = {
          q: ids.map(id => `id=${id}`).join(' or ')
        }
        const resource_path = `${resource}?${stringify(query)}`;
        let apiService = new ApiService();
        return apiService.getApi(resource_path)
          .then(response => response.json())
          .then(json => convertApiResponse(json, 'MULTI', resource, params));
    },

    getManyReference: (resource, params) => {
      const { page, perPage } = params.pagination;
        let query = {
            p: !!page ? page : 1,
            size: !!perPage ? perPage : 10,
            // filter: JSON.stringify(params.filter),
        };
        // sort
        const { field, order } = params.sort;
        if(!!field) {
            query.order = field;
            query.asc = order === 'ASC';
        }        
        const resource_path = `${resource}?${stringify(query)}`;
        let apiService = new ApiService();
        return apiService.getApi(resource_path)
          .then(response => response.json())
          .then(json => convertApiResponse(json, 'MULTI', resource, params));
    },

    update: async (resource, params) => {
      const resource_path = `${resource}/${params.id}`;
      let apiService = new ApiService();

      const data = await convertApiRequestData(resource, params, settings);
      return apiService.putApi(resource_path, JSON.stringify(data))
      .then(response => response.json())
      .then(json => convertApiResponse(json, 'SINGLE', resource, params));    
    },

    /*
    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    */

    create: async (resource, params) => {
        const resource_path = `${resource}`;
        let apiService = new ApiService();
        const data = await convertApiRequestData(resource, params, settings);
        return apiService.postApi(resource_path, JSON.stringify(data))
            .then(response => response.json())
            .then(json => convertApiResponse(json, 'SINGLE', resource, params));
    },
    
    delete: (resource, params) => {
        const resource_path = `${resource}/${params.id}`;
        let apiService = new ApiService();
        return apiService.deleteApi(resource_path)
            .then(response => response.json())
            .then(json => convertApiResponse(json, 'SINGLE', resource, params));
    },
    
    /*
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    */
});

export default dataProvider;