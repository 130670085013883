/* --------------- GENERICS --------------- */
import React, { useContext, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { 
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EmailField,
  Filter,
  ImageField,
  List,
  ListButton,
  NumberField,
  NumberInput,
  SelectField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  required,
} from 'react-admin';


import { SettingsContext } from '../../Context';
import GenericBooleanField from './GenericBooleanField';
import GenericImageListField from './GenericImageListField';
import GenericTextListField from './GenericTextListField';
import GenericImageInput from './GenericImageInput';
import GenericReferenceInput from './GenericReferenceInput';
import GenericReferenceArrayInput from './GenericReferenceArrayInput';
import GenericReferenceArrayField from './GenericReferenceArrayField';
import GenericReferenceField from './GenericReferenceField';
import { getChoices } from '../../enums';

/**
 *     Common functions
 */
/* --------------- GET FIELDS FROM SETTINGS --------------- */
const getFieldsFromSettings = (mode, allSettings, type) => {
  
  // filter settings
  const settings = allSettings.Types[type];

  // get fields from settings
  let fields = (settings[mode] && settings[mode]?.fields) || settings?.fields || undefined;

  // generate exclude list uing global and mode
  const global_exclude = settings.exclude || []; 
  const view_exclude = (settings[mode] && settings[mode]?.exclude) || [];
  let exclude = [...global_exclude, ...view_exclude];
  
  // in create mode we filter readOnly fields
  if(mode === 'create') {
    let roFields = Object.keys(settings.properties).filter( k => settings.properties[k].readOnly); 
    roFields = [...roFields, ...(settings.readOnly || [])]
    exclude = [...exclude, ...roFields]
  }

  if(fields) {
    fields = fields.filter(f => !exclude.includes(f))
  }
  return fields
}

/* --------------- GET COMPONENTS FROM SETTINGS --------------- */
const getComponentsFromSettings = (mode, allSettings, type) => {
  
  // filter settings for type
  const settings = allSettings.Types[type];

  // get global components
  let globalComponents = {};
  if(['list', 'show'].includes(mode) && settings?.fieldComponents) {
    globalComponents = settings.fieldComponents;
  }
  else if(['edit', 'create'].includes(mode) && settings?.inputComponents) {
    globalComponents = settings.inputComponents;
  }
  // get components from view settings
  const components = (settings[mode] && settings[mode]?.components) || {};
  return {...globalComponents, ...components}
}


/*  -----------  Rest Props Sanitation for actions ----------- */
const sanitizeRestProps = ({
  basePath,
  className,
  record,
  hasEdit,
  hasList,
  hasCreate,
  hasShow,
  resource,
  ...rest
}) => rest;


/**
 *    GENERIC TITLE (Show and Edit)
 */
export const GenericTitle = ({record, type}) => {
  const [allSettings] = useContext(SettingsContext)  
  const settings = allSettings.Types[type];
  return <span>{`${type}: ${record[settings.nameField]}`}</span>;
};


/**
 *     LIST
 */
/* --------------- GENERIC LIST --------------- */

const ListFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);


export const GenericList = (props) => {
  return (
    <List {...props} filters={<ListFilter variant='outlined'/>} sort={{ field: 'id', order: 'DESC' }}>
      <GenericDatagrid type={props.options.type} />
    </List>
  )
}

/* --------------- GENERIC DATAGRID --------------- */
export const GenericDatagrid = ({ type, ...props}) => {
  const [settings] = useContext(SettingsContext)
  const [fields] = useState(getFieldsFromSettings('list', settings, type))
  const [components] = useState(getComponentsFromSettings('list', settings, type))

  if(fields == null || components == null) return <CircularProgress size={100} color="primary" />

  return (
    <Datagrid {...props} rowClick="edit">
      { fields.map((field, idx) => createElement(settings, type, field, idx, components, 'list'))}
    </Datagrid>
  )
}

/**
 *     SHOW
 */
/* --------------- GENERIC SHOW --------------- */

export const GenericShowActions = ({ basePath, className, data, hasEdit, hasList, ...rest}) => {
  return (
    <TopToolbar className={className} {...sanitizeRestProps(rest)} >
      { hasList && <ListButton basePath={basePath} /> }
      { hasEdit && <EditButton basePath={basePath} record={data} /> }
    </TopToolbar>
  )
}

export const GenericShow = ({ hasShow, ...props}) => {

  const [settings] = useContext(SettingsContext)
  const type = props.options.type;
  const typeSettings = settings.Types[props.options.type]
  return (
    <Show 
      title={!!typeSettings?.nameField ? <GenericTitle record={props.record} type={type} /> : undefined} 
      actions={<GenericShowActions {...props} />} 
      {...props} 
    >
      <GenericShowSimpleLayout type={type} />
    </Show>
  )
}

/* --------------- GENERIC SHOW SIMPLELAYOUT --------------- */
export const GenericShowSimpleLayout = ({type, ...props}) => {

  const [settings] = useContext(SettingsContext)
  const [fields] = useState(getFieldsFromSettings('show', settings, type))
  const [components] = useState(getComponentsFromSettings('show', settings, type))

  if(fields == null || components == null) return <CircularProgress size={100} color="primary" />

  return (
    <SimpleShowLayout {...props}>
      { fields.map((field, idx) => createElement(settings, type, field, idx, components, "show"))}
    </SimpleShowLayout>
  )  
}


/**
 *     EDIT
 */
/* --------------- GENERIC EDIT --------------- */

export const GenericEditActions = ({ basePath, className, data, hasShow, hasList, ...rest}) => {
  return (
    <TopToolbar className={className} {...sanitizeRestProps(rest)} >
      { hasList && <ListButton basePath={basePath} /> }
      { hasShow && <ShowButton basePath={basePath} record={data} /> }
    </TopToolbar>
  )
}

export const GenericEdit = (props) => {

  const [settings] = useContext(SettingsContext)
  const type = props.options.type;
  const typeSettings = settings.Types[props.options.type]

  return (
    <Edit 
      title={!!typeSettings?.nameField ? <GenericTitle record={props.record} type={type} /> : undefined} 
      actions={<GenericEditActions {...props} />} {...props}
    >
      <GenericEditSimpleForm type={type} variant='outlined'/>
    </Edit>
  )
}

/* --------------- GENERIC EDIT SIMPLEFORM --------------- */
export const GenericEditSimpleForm = ({type, variant, ...props}) => {

  const [settings] = useContext(SettingsContext)
  const [fields] = useState(getFieldsFromSettings('edit', settings, type))
  const [components] = useState(getComponentsFromSettings('edit', settings, type))

  if(fields == null || components == null) return <CircularProgress size={100} color="primary" />

  return (
    <SimpleForm {...props} variant={variant} >
      { fields.includes('id') &&
        <TextInput disabled source='id'/>
      }
      { fields.filter(f => f !== 'id').map((field, idx) => createElement(settings, type, field, idx, components, "edit"))}
    </SimpleForm>
  )  
}

/**
 *     CREATE
 */
/* --------------- GENERIC CREATE --------------- */

export const GenericCreateActions = ({ basePath, className, data, hasList, ...rest}) => {
  return (
    <TopToolbar className={className} {...sanitizeRestProps(rest)} >
      { hasList && <ListButton basePath={basePath} /> }
    </TopToolbar>
  )
}

export const GenericCreate = (props) => {
  const type = props.options.type;
  return (
    <Create actions={<GenericCreateActions {...props} />} {...props} >
      <GenericCreateSimpleForm type={type} variant='outlined' />
    </Create>
  )
}

/* --------------- GENERIC CREATE SIMPLEFORM --------------- */
export const GenericCreateSimpleForm = ({type, variant, ...props}) => {

  const [settings] = useContext(SettingsContext)
  const [fields] = useState(getFieldsFromSettings('create', settings, type))
  const [components] = useState(getComponentsFromSettings('create', settings, type))

  if(fields == null || components == null) return <CircularProgress size={100} color="primary" />

  return (
    <SimpleForm {...props} variant={variant} >
      { fields.filter(f => f !== 'id').map((field, idx) => createElement(settings, type, field, idx, components, "create"))}
    </SimpleForm>
  )  
}



/**
 * 
 * --------------- ELEMENTS CREATION FUNCTIONS ---------------  
 * 
 */
const createElement = (allSettings, type, field, idx, components, mode) => {


  if (components && components[field]) { 
    let element = components[field];
    if (Array.isArray(element)) {
      return React.createElement(element[0], { key: idx.toString(), source: field, ...element[1]})
    }
    return React.createElement(components[field], { key: idx.toString(), source: field})
  }
  else if (['list', 'show'].includes(mode)) {
    return createGenericFieldElement(field, idx, allSettings, type, mode)
  }
  else if (['edit','create'].includes(mode)) {
    return createGenericInputElement(field, idx, allSettings, type, mode)
  }
  else {
    console.error('mode undefined in createElement')
  }
}

/**
 * --------------- FIELD ELEMENTS --------------- 
 */
const createGenericFieldElement = (field, idx, allSettings, type, mode) => {

  // filter settings for type
  const settings = allSettings.Types[type];

  // extract field properties from schema
  const properties = {...settings.properties[field], required: settings?.required?.includes(field) ? true : false};

  let element;
  let props={source: field};

  switch (properties.type) {
    case 'string':
      switch (properties.format) {
        case 'email':
          element = EmailField
          break
        case 'date-time':
          element = DateField
          props.showTime = true
          // props.locales = "fr-FR"
          break
        default:
          // Adapt to list and image fields
          element = TextField;
          const isImage = field.slice(-5).toLowerCase() === 'image';
          switch(mode) {
            case 'list':
              element = isImage ? GenericImageListField : GenericTextListField;
              break;
            default:
              element = isImage ? ImageField : TextField;
          }
      }
      break;
    case 'number':
    case 'integer':
      element = NumberField
      switch(properties.format) {
        case 'int32':
          // Treat fields ending by Id
          if(field.slice(-2) === 'Id') {
            const refName = field.slice(0, field.length - 2)
            const potentialTypes = Object.keys(allSettings.Types).filter(k => k.toLowerCase() === refName.toLowerCase())
            let refType;
            if(potentialTypes.length > 0) {
              refType = potentialTypes[0];
            }
            if(refType) {
              element = GenericReferenceField
              const refSettings = allSettings.Types[refType]
              props.reference = refSettings.resource
              props.textSource = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
            }
          }
          else {
            props.options = { maximumFractionDigits: 0 }
          }
          break
        case 'double':
          props.options = { maximumFractionDigits: 2 }
          break
        default:
          props.options = { maximumFractionDigits: 2 }
      }
      break;
    case 'boolean':
      element = GenericBooleanField
      break
    case 'array':
      if(properties?.items?.$ref) {
        const refType = properties.items.$ref.split('/').slice(-1)[0]
        return createGenericReferenceArrayField(field, idx, allSettings, type, mode, refType)
      }
    // eslint-disable-next-line no-fallthrough
    default:
      if(properties.type === undefined && properties.$ref) {
        const refType = properties.$ref.split('/').slice(-1)[0]
        return createGenericReferenceField(field, idx, allSettings, type, mode, refType)
      }
      else {
        console.log(`!!! Default TextField used for ${field} : ${JSON.stringify(properties || {})}`)
      }
      element = TextField
  }
  return React.createElement(element, { key: idx.toString(), ...props})
}

/* -------- create Generic Reference Field --------  */
const createGenericReferenceField = (field, idx, allSettings, type, mode, refType) => {
  const refSettings = allSettings.Types[refType]
  // console.log('refSettings:',refSettings)
  // const refProperties = {...((refSettings?.properties && refSettings?.properties[field]) || {}), required: refSettings?.required?.includes(field) ? true : false};
  // console.log('refProperties:',refProperties)

  let element = TextField
  let props = { source: field }
  //enum case
  if(refSettings.enum && refSettings.enum.length) {
    element = SelectField
    props.choices = getChoices(refType, refSettings.enum);  // refSettings.enum.map((id) => ({id: id, name: id.toString()}));
  }
  else if(field.slice(-2) !== 'Id') {
    element = GenericReferenceField
    props.reference = refSettings.resource
    props.textSource = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
  }
  else {
    console.log(`!!! Default TextField used for Reference ${field}`)
  }  
  return React.createElement(element, { key: idx.toString(), ...props}) 
}

/* -------- create Generic Reference Array Field --------  */
const createGenericReferenceArrayField = (field, idx, allSettings, type, mode, refType) => {
  const refSettings = allSettings.Types[refType]
  // console.log('refSettings:',refSettings)
  // const refProperties = {...((refSettings?.properties && refSettings?.properties[field]) || {}), required: refSettings?.required?.includes(field) ? true : false};
  // console.log('refProperties:',refProperties)
  
  let element = TextField
  let props = { source: field }

  // We suppose we have an object list if field does not end with Ids
  if(field.slice(-3) !== 'Ids') {
      element = GenericReferenceArrayField
      props.reference = refSettings.resource
      props.resource = refSettings.resource
      props.textSource  = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
  }
  else {
    console.log(`!!! Default TextField used for Reference Array ${field}`)
  }

  return React.createElement(element, { key: idx.toString(), ...props}) 
}


/** 
 * --------------- INPUT ELEMENTS --------------- 
 */ 
const createGenericInputElement = (field, idx, allSettings, type, mode) => {

  // filter settings for type
  const settings = allSettings.Types[type];

  // extract field properties from schema
  const properties = {
    ...settings.properties[field], 
    required: settings?.required?.includes(field) ? true : false
  };
  
  // Check for readonly property
  if(properties.readOnly === true || (settings.readOnly && settings.readOnly.includes(field))) {
    return createGenericFieldElement(field, idx, allSettings, type, "show");
  }

  let element;
  let props={source: field};
  if(mode === 'create') {
    props.allowEmpty = true
  }
  if (properties.required) {
    props.validate = [required()]
  }
  switch (properties.type) {
    case 'string':
      switch (properties.format) {
        case 'email':
          element = TextInput
          props.type = "email"
          break
        default:
          const isImage = field.slice(-5).toLowerCase() === 'image';
          element = isImage ? GenericImageInput : TextInput;
      }
      break;
    case 'number':
    case 'integer':
      element = NumberInput
      switch(properties.format) {
        case 'int32':
          // Treat fields ending by Id
          if(field.slice(-2) === 'Id') {
            const refName = field.slice(0, field.length - 2)
            const potentialTypes = Object.keys(allSettings.Types).filter(k => k.toLowerCase() === refName.toLowerCase())
            let refType;
            if(potentialTypes.length > 0) {
              refType = potentialTypes[0];
            }
            if(refType) {
              element = GenericReferenceInput
              const refSettings = allSettings.Types[refType]
              props.reference = refSettings.resource
              props.optionText = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
            }
          }
          else {
            props.step = 1
          }
          break
        case 'double':
          props.step = 0.01
          break
        default:
          props.step = 0.01
      }
      break;
    case 'boolean':
      element = BooleanInput
      break  
    case 'array':
      if(properties?.items?.$ref) {
        const refType = properties.items.$ref.split('/').slice(-1)[0]
        return createGenericReferenceArrayInput(field, idx, allSettings, type, mode, refType)
      }
    // eslint-disable-next-line no-fallthrough
    default:
      if(properties.type === undefined && properties.$ref) {
        const refType = properties.$ref.split('/').slice(-1)[0]
        return createGenericReferenceInput(field, idx, allSettings, type, mode, refType)
      }
      else {
        console.log(`!!! Default TextInput used for ${field} : ${JSON.stringify(properties || {})}`)
        element = TextInput;
      }
  }
  return React.createElement(element, { key: idx.toString(), ...props})
}

/* -------- create Generic Reference Input --------  */
const createGenericReferenceInput = (field, idx, allSettings, type, mode, refType) => {
  const refSettings = allSettings.Types[refType]
  // const refProperties = {...((refSettings?.properties && refSettings?.properties[field]) || {}), required: refSettings?.required?.includes(field) ? true : false};
  // console.log('refProperties:',refProperties)

  let element = TextInput
  let props = { source: field }
  //enum case
  if(refSettings.enum && refSettings.enum.length) {
    element = SelectInput
    props.choices = getChoices(refType, refSettings.enum);  // refSettings.enum.map((id) => ({id: id, name: id.toString()}));
  }
  else if(field.slice(-2) !== 'Id') {
    element = GenericReferenceInput
    const optionText  = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
    props.reference = refSettings.resource
    props.optionText = optionText
    // props.source = props.source + '.' + optionText
  }
  else {
    console.log(`!!! Default TextInput used for Reference ${field}`)
  }  
  return React.createElement(element, { key: idx.toString(), ...props}) 
}

/* -------- create Generic Reference Array Field --------  */
const createGenericReferenceArrayInput = (field, idx, allSettings, type, mode, refType) => {
  const refSettings = allSettings.Types[refType]
  // const refProperties = {...((refSettings?.properties && refSettings?.properties[field]) || {}), required: refSettings?.required?.includes(field) ? true : false};
  // console.log('refProperties:',refProperties)
  
  let element = TextInput
  let props = { source: field }

  // We suppose we have an object list if field does not end with Ids
  if(field.slice(-3) !== 'Ids') {
      element = GenericReferenceArrayInput
      props.reference = refSettings.resource
      props.optionText  = refSettings.nameField || (refSettings.fields.includes('name') ? 'name' : 'id')
  }
  else {
    console.log(`!!! Default TextInput used for Reference Array ${field}`)
  }
  return React.createElement(element, { key: idx.toString(), ...props}) 
}
