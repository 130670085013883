import * as React from 'react';

/* Material-ui */
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

/* React-Admin */
import { useLogin, useNotify, Notification} from 'react-admin';

/* Constants */
const logoPath = "/images/FCST_LOGO_RVB_VERT.svg"

/**
 * Login Page
 */
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        height: '100vh',
        textAlign: 'center',
    },
    logo: {
      height:'80%',
      width: 'auto',
      margin: 'auto',
      marginTop: '10vh',
    }
  })
);

const LoginPage = ({ theme }) => {
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();
    
    const imageClick = () => {
        login()
            .catch(() => notify('Invalid email or password'));
    };
    
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
              <img alt="logo" src={logoPath} className={classes.logo} onClick={() => imageClick()} />
            </div>
            <Notification />
        </ThemeProvider>
    );
};

export default LoginPage;