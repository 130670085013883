import React from 'react';

import { 
    ImageField
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    image: {
        height: 100,
        width: 'auto',
        maxWidth: 200,
        // objectFit: 'contain',
    }
}));

const GenericImageListField = (props) => {
  const classes = useStyles();
  return <ImageField classes={classes} {...props} />
}
GenericImageListField.defaultProps = { addLabel: true }

export default GenericImageListField;