import React from 'react';

import { 
    ImageField,
    ImageInput,
} from 'react-admin';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    image: {
        maxWidth: 256,
        height: 'auto',
        marginLeft: 0,
        marginRight: 0
    }
}));

const PreviewImageField = ({ record }) => {
    const classes = useStyles();
    let imageRecord = { url: "" };
    if(typeof record === "string") imageRecord.url = record;
    else imageRecord.url = record.undefined;  // strange but works

    return (
        <ImageField classes={classes} record={imageRecord} source={"url"} />
    )
}

const GenericImageInput = (props) => {
    const { source } = props;
    return (
        <div style={{maxWidth: 256}}>
            <ImageInput {...props} source={source}  accept="image/*">
                <PreviewImageField />
            </ImageInput>
        </div>
    )
}

export default GenericImageInput;