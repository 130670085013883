import AuthService, { hasSignedIn } from './AuthService';
import Env from '../settings';
const authProvider = {
  // called when the user attempts to log in
  login: async () => {
    let authService = new AuthService()
    await authService.login()
    return Promise.resolve()
  },
  
  // called when the user clicks on the logout button
  logout: async () => {
    if(hasSignedIn()) {
      let authService = new AuthService()
      await authService.logout();
    }
    return Promise.resolve();
  },
  
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      return Promise.reject(); // reject calls logout !!!!
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const signedIn = hasSignedIn();
    if (signedIn) {
      // Check if prefered_username ends with @sytadelle.fr
      const token = JSON.parse(sessionStorage.getItem('oidc.user:' + Env.authority + ':' + Env.clientId))
      if(token?.profile?.preferred_username?.endsWith('@sytadelle.fr')) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
    return Promise.reject();
  },
  
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;