// Global Api Settings

import {
  EmailField,
  TextInput,
  UrlField,
  maxLength,
  minLength,
} from 'react-admin';

import Env from './settings';

/**
 * How to configure overrides of default construction
 *  
 * For each type you can define:
 * 
 * NO - A reference object so referencFields will know what to fetch
 * - A global fields list (else it will display all fields from schema)
 * - a global exclude (wil be forced on each view)
 * - a nameField used for titles in show and edit
 * 
 * - for each view (list, show, edit and create):
 *    - a fields list (will override global list or default fields), it will also define fields generation order in views
 *    - an exclude list (will be added to global exclude)
 *    - override components with components objects, a list with Component and additional props:
 *      Example to override the default component for field name on show view of User type 
 *        by a TextField component with text in blue:
 *      ...
 *      Types: {
 *        User: {
 *          nameField: 'name',          //note: if not provided, name will be used if found in fields list, else id
 *          fields: ['id', 'name']      // global list of fields
 *          exclude: ['owner']          // global exclude fields
 *          fieldComponents: {          // a global components overrides for list and show views
 *          },
 *          inputComponents: {          // a global components overrides for show and create views
 *          },
 *          show: {
 *            components: {
 *              name: [ TextField, {style: { color: 'blue'}}]
 *            }
 *          }
 *        }
 *      } 
 */

const apiSettings = {
  Global: {
    schemaUrl: Env.faiApiEndpoint.slice(0,-4) + 'swagger/v1/swagger.json',
    // Fill this array to exclude any schema type from automatic generation (eg: Tag, User, ...)
    excludedTypes: ['ImageFile', 'Operation' ]
  },
  Types: {
    /* ------------------- USER ------------------- */
    User: {
      readOnly: ['guid'],
      list: {
        exclude: ['personalProfile', 'organizationProfiles'],
      },
      create: {
        disabled: true
      }
    },

    /* ------------------- PROFILE ------------------- */ 
    Profile: {
      fieldComponents: {
        webSite: [UrlField, { target: "_blank", rel: "noopener"}],
      },
    },

    /* ------------------- COUNTRY ------------------- */ 
    Country: {
      inputComponents: {
        code: [ TextInput, { validate: [minLength(2),maxLength(2)] }]
      }
    },

    /* ------------------- RATING ------------------- */ 
    Rating: {},

    /* ------------------- TAG ------------------- */ 
    Tag: {},

    /* ------------------- NOTIFICATION ------------------- */ 
    Notification: {
      fieldComponents: {
        mailTo: EmailField,
      },
      inputComponents: {
        mailTo: [TextInput, { type: 'email', parse: (value) => (!!value ? value : "")}],
      }
    },

    /* ------------------- SERVICE ------------------- */ 
    Service: {},

    /* ------------------- USER PROFILE ------------------- */ 
    UserProfile: {},

    /* ------------------- POST ------------------- */ 
    Post: {
      fieldComponents: {
        mediaUrl: UrlField,
      },
      inputComponents: {
        content: [TextInput, {multiline: true}]
      }
    },
    
    /* ------------------- POST ------------------- */ 
    ProfileLink: {},

    /* ------------------- HASHTAG ------------------- */ 
    Hashtag: {}

  }
}
 
export default apiSettings;
