
import React from 'react';

import { 
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';

// import { recordFieldToId } from '../../utils';
// Todo: use RecordFieldToId ? should be the same function
export const GenericReferenceArrayInput = ({source, reference, optionText, ...props}) => {
  let { record } = props;

  let ids = [];
  if(record && record[source] && record[source].length > 0) {
    ids = record[source].map((v, idx) => {
      if(v && v.id) { return v.id }
      else { return v;}
    });
  }
  record[source] = ids.map(i => ({id: i}));
  // const InputComponent = (props) => ['tags'].includes(reference) ? <AutocompleteArrayInput {...props} /> : <SelectArrayInput {...props} />;
  const InputComponent = (props) => {
    if(optionText === 'id') {
      return <SelectArrayInput {...props} />
    }
    return <AutocompleteArrayInput {...props} />
  }

  return (
    <div style={{maxWidth: 256}} >
      <ReferenceArrayInput {...props} 
        record={record} 
        source={source} 
        reference={reference} 
        addLabel={true}
        format={value => {
          let res = (value && value.length) ? value.map(v => (v.id)) : value;
          return res;
        }}
        parse={value => {
          let res = value.map(v => ({id: v}));
          return res
        }}
      >
        <InputComponent optionText={optionText} />
      </ReferenceArrayInput>
    </div>
  )
}

export default GenericReferenceArrayInput;