export const pluralize = (word) => {
    word = word.trim();
    if (word.endsWith("s")) return word + "es";
    if (word.endsWith("y")) return word.slice(0, word.length -1) + "ies";
    return word + "s";
}

export const getResourceName = (type) => pluralize(type).toLowerCase()

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const singularize = (word) => {
    word = word.trim();
    if (word.endsWith("ies")) return word.slice(0,-3) + "y";
    if (word.endsWith("es")) return word.slice(0,-1);
    return word.slice(0,-1);
}

export const getTypeName = (resource, settings) => {
    let singular = singularize(resource);
    let types = Object.keys(settings.Types).filter(k => k.toLowerCase() === singular.toLowerCase() ? true : false)
    if(types?.length > 0) {
        return types[0]
    }
    return toTitleCase(singular);
}

export function getEntityFromSchema(schema, type) {

    const matches = Object.entries(schema?.components?.schemas || {}).filter(e => e[0] === type);
    if(matches.length === 0) return null;
    if(matches.length === 1) return matches[0]
    console.warn('multiple entities match in schema, returnning first one !')
    return matches[0]
}

export function recordFieldToId(record, field) {
    let value = record && record[field]
    if(value) {
        if( Array.isArray(value)){
            value = value.map(v => (typeof v === 'object' ? (v && v.id) : v))
        }
        else {
            value = typeof value === 'object' ? value.id : value
        }
        record[field] = value
    }
    return record;
}
