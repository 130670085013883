import React, { useEffect, useState } from 'react';

import apiSettings from './apiSettings';
import authProvider from './auth/authProvider';
import dataProvider from './api/dataProvider';
import i18nProvider from './i18n/i18nProvider';

import LoginPage from './auth/LoginPage';
import theme from './theme';

import { SettingsContext } from './Context';
import { GenericAdmin } from './Components/Generics';
import { getResourceName } from './utils';

const { schemaUrl } = apiSettings.Global;

const App = () => {
  const [settings, setSettings] = useState(null);

  // Load API schema then merge with apiSettings in SettingsContext
  useEffect( () => {
    const getSettings = async () => {
      let response = await fetch(schemaUrl)
      let schema = await response.json()
      // display schema in console in development
      if(process.env.NODE_ENV !== 'production') {
        console.log('Received schema from api: ' + schemaUrl)
        console.log(schema)
      }
      // merge schema into apiSettings
      let settings = apiSettings;
      const schemas = schema.components.schemas;
      Object.keys(schemas).forEach((type)=>{
        // merge
        settings.Types[type] = {...settings.Types[type] || {}, ...schemas[type] }
        delete settings.Types[type].type //no need
        settings.Types[type].resource = getResourceName(type)
        // Compute all fields if not already defined in settings 
        if(!settings.Types[type].fields) {
          let fields = Object.keys(schemas[type]?.properties || {})
          // pass 'id' in first position
          if(fields.includes('id')) {
            fields = ['id', ...fields.filter(f => f !== 'id')]
          }
          settings.Types[type].fields = fields;
        }
      })
      if(process.env.NODE_ENV !== 'production') {
        console.log('Settings generated: ')
        console.log(settings)
      }
      setSettings(settings)
    }    
    getSettings();
  }, []);
  
  return (
    <SettingsContext.Provider value={[settings, setSettings]}>
      <GenericAdmin 
        dataProvider={dataProvider(settings)} 
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        theme={theme}
        loginPage={LoginPage}
      />
    </SettingsContext.Provider>
  );
}

export default App;
